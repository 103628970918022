<template>
	<div id="result">
		<webHeader></webHeader>
		<div class="resultBox">
			<div class="result" v-if="active == 1">
				<div class="icon">
					<i class="el-icon-check"></i>
				</div>
				<h1>类脑知识训练已开始</h1>
				<p>
					已经完成基础类脑创建，知识处理训练仍需要7天时间<br>
					你可以通过小程序继续进行类脑训练
				</p>
				<el-button @click="toForm()">继续上传</el-button>
			</div>

			<div class="result" v-if="active == 2">
				<div class="icon">
					<i class="el-icon-check"></i>
				</div>
				<h1>网页抓取已定向</h1>
				<p>
					您指定的网络地址，由于对象网络的安全策略，部分网页<br>
					内容可能无法通过技术手段完成内容抓取
				</p>
				<el-button @click="toForm()">继续上传</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import webHeader from '@/components/header.vue'
	export default {
		name: 'Result',
		components: {
			webHeader
		},
		data() {
			return {
				active: 1
			}
		},
		created() {
			this.active = this.$route.query.act;
		},
		mounted() {},
		methods: {
			toForm() {
				this.$router.push({
					name: 'Form'
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	#result {
		width: 100vw;
		height: 100vh;
		overflow: hidden;

		.resultBox {
			width: 100%;
			height: 100%;
			padding: 30px;
			background: #0065FF;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			overflow-y: scroll;
		}

		.result {
			width: 1210px;
			height: 600px;
			background-color: #343549;
			border-radius: 15px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin: 10px;
			box-shadow: 0 0 30px rgba(0, 0, 0, .2);

			.icon {
				width: 228px;
				height: 228px;
				opacity: 1;
				background: -webkit-linear-gradient(158deg, rgba(3, 239, 255, 1), rgba(0, 224, 239, 0));
				background: -moz-linear-gradient(158deg, rgba(3, 239, 255, 1), rgba(0, 224, 239, 0));
				background: -o-linear-gradient(158deg, rgba(3, 239, 255, 1), rgba(0, 224, 239, 0));
				background: linear-gradient(158deg, rgba(3, 239, 255, 1), rgba(0, 224, 239, 0));
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 20px;

				i {
					border: 6px solid #fff;
					font-size: 90px;
					border-radius: 44px;
					padding: 5px;
					color: #fff;
				}
			}

			h1 {
				font-size: 30px;
				color: #fff;
				margin-bottom: 10px;
			}

			p {
				font-size: 14px;
				color: #A6A6A6;
				margin-bottom: 50px;
			}

			.el-button {
				height: 46px;
				width: 800px;
				font-size: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #2F2EE9;
				color: #fff;
				border: none;
				border-radius: 10px;
			}
		}
	}
</style>